export const API_URL = process.env.REACT_APP_API_ENDPOINT;
export const ENV_TYPE = process.env.REACT_APP_ENV_TYPE;
export const APP_NAME = process.env.REACT_APP_BASENAME;
export const LOGIN_PLATFORM = {
  GOOGLE: 1,
  GITHUB: 2,
  SELF: 3
};
export const ATTACHMENT_TYPE = {
  VIDEO: 1,
  IMAGE: 2
};

export const PLATFORM_INTEGRATION = {
  SLACK: 1,
  TRELLO: 2,
  JIRA: 3
};

export const PLATFORM_TYPE = 2;

export const STORAGE_KEYS = {
  WORKSPACE: 'work-space-details',
  PROJECT: 'project-details',
  LAST_SELECTED_PROJECT: 'last-selected-project'
};

export const REFRESH_COMPONENTS = {
  WORKSPACE_LIST: 'workspace_list',
  PROJECT_LIST: 'project_list'
};

const generateRandomString = () => {
  return Math.random().toString(36).slice(2);
};

export const SLACK_URL = `https://slack.com/oauth/v2/authorize?client_id=5069963309526.5062040477655&scope=channels:read,chat:write,chat:write.public,links:write,links.embed:write,links:read&user_scope=`;
export const JIRA_URL = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=eTLJs5xdFdKEf5UYBjVrJyaUPveMkYt7&scope=offline_access%20read%3Ajira-work%20manage%3Ajira-configuration%20read%3Ajira-user%20write%3Ajira-work&redirect_uri=https%3A%2F%2Fbugmanagement.dev.innvonix.in%2Fintegration%3Fplatform%3Djira&state=${generateRandomString()}&response_type=code&prompt=consent`;
export const TRELLO_URL = `https://trello.com/1/authorize?expiration=never&name=${encodeURIComponent(
  'Innvonix Bug Management Tool'
)}&scope=read,write&response_type=token&key=fec2d43a8e3c36e4166863530c22a627&return_url=https://bugmanagement.dev.innvonix.in/integration?platform=trello`;
