/**
 * create combine reducer
 */
import { combineReducers } from 'redux';

import auth from './auth';
import layout from './layout';
import navbar from './navbar';
import general from './general';

const rootReducer = combineReducers({
  auth,
  layout,
  navbar,
  general
});

export default rootReducer;
