// Initial States
const initialState = {
  userData: {},
  verify_email: '',
  token: null,
  isAuthenticated: false
};

const authReducer = (state = initialState, action) => {
  // console.log('action', action);
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        userData: action.data.user,
        token: action.token,
        isAuthenticated: true
      };
    case 'VERIFY_EMAIL':
      return {
        ...state,
        verify_email: action.data
      };
    case 'SET_USER_INFO':
      return {
        ...state,
        userData: action.data,
        isAuthenticated: true
      };
    case 'LOGOUT':
      return { ...state, userData: {}, token: null, isAuthenticated: false };
    case 'RESET_USER_AUTH':
      return { ...state, userData: {}, token: null, isAuthenticated: false };
    default:
      return state;
  }
};

export default authReducer;
