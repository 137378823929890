// Initial States
const initialState = {
  refreshDataDetails: {
    component: '',
    details: {},
    toggle: false
  },
  redirectURL: undefined,
  imageChange: false,
  metaData: {}
};

const generalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'REFRESH_DATA':
      return {
        ...state,
        refreshDataDetails: {
          component: action.data.component,
          details: action.data.details,
          toggle: !state.refreshDataDetails.toggle
        }
      };
    case 'SET_REDIRECT_URL':
      return {
        ...state,
        redirectURL: action.data
      };
    case 'SET_IMAGE_EDIT':
      return {
        ...state,
        imageChange: action.data
      };
    case 'SET_META_DATA':
      return {
        ...state,
        metaData: action.data
      };
    default:
      return state;
  }
};

export default generalReducer;
