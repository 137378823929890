// Import Theme Config
import themeConfig from '@configs/themeConfig'

// Initial States
const initialState = {
    skin: themeConfig.layout.skin,
    isRTL: themeConfig.layout.isRTL,
    layout: themeConfig.layout.type,
    lastLayout: themeConfig.layout.type,
    footerType: themeConfig.layout.footer.type,
    navbarType: themeConfig.layout.navbar.type,
    menuHidden: themeConfig.layout.menu.isHidden,
    contentWidth: themeConfig.layout.contentWidth,
    menuCollapsed: themeConfig.layout.menu.isCollapsed,
    routerTransition: themeConfig.layout.routerTransition,
    navbarColor: themeConfig.layout.navbar.backgroundColor
}

// Reducer
const layoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_SKIN':
            window.localStorage.setItem('skin', JSON.stringify(action.value))
            return {...state, skin: action.value}
        case 'HANDLE_RTL': 
            window.localStorage.setItem('direction', JSON.stringify(action.value))
            return {...state, isRTL: action.value}
        case 'HANDLE_LAYOUT':
            return {...state, layout: action.value}
        case 'HANDLE_FOOTER_TYPE':
            return {...state, footerType: action.value}
        case 'HANDLE_NAVBAR_TYPE':
            return {...state, navbarType: action.value}
        case 'HANDLE_MENU_HIDDEN':
            return {...state, menuHidden: action.value}
        case 'HANDLE_LAST_LAYOUT':
            return {...state, lastLayout: action.value}
        case 'HANDLE_NAVBAR_COLOR':
            return {...state, navbarColor: action.value}
        case 'HANDLE_CONTENT_WIDTH':
            return {...state, contentWidth: action.value}
        case 'HANDLE_MENU_COLLAPSED':
            window.localStorage.setItem('menuCollapsed', JSON.stringify(action.value))
            return {...state, menuCollapsed: action.value}
        case 'HANDLE_ROUTER_TRANSITION':
            return {...state, routerTransition: action.value}
        default : 
            return state
    }
}

export default layoutReducer